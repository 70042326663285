
// Fonts
//@import url('https://fonts.googleapis.com/css?family=Nunito');

// Variables
@import 'variables';

@import '~normalize.css/normalize';

dd {
  margin-inline-start: 0;
}
ul {
  list-style: none;
  margin: 0;
  padding: 0;
}

.topTable{
    margin-bottom:25px;
}
